import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../../components/Layout';

export const pageQuery = graphql`
    query ($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                slug
                title
                date
            }
        }
    }
`;

const Navigation = () => {
    return (
        <nav className="h-20 container border-b-2 border-black border-dotted mx-auto">
            <div className="h-full grid grid-cols-2 justify-between items-center">
                <div className="">
                    <Link
                        className="uppercase transform-color ease-in duration-300 hover:text-sky-700"
                        to="/">
                        Nathan Hefner
                    </Link>
                </div>
                <div className="text-right">
                    <Link
                        className="ml-6 uppercase transform-color ease-in duration-300 hover:text-sky-700"
                        to="/projects/">
                        Projects
                    </Link>
                    <Link
                        className="ml-6 uppercase transform-color ease-in duration-300 hover:text-sky-700"
                        to="/writing/">
                        Writing
                    </Link>
                </div>
            </div>
        </nav>
    );
};

const PageWrapper = (props: { children: React.ReactNode }) => {
    return <div className="bg-[#eadbc6] min-h-screen">{props.children}</div>;
};

const PostContainer = (props: { children: React.ReactNode }) => {
    return <div className="container mx-auto py-12">{props.children}</div>;
};

const PostHeader = (props: { title: string; date: string }) => {
    const { title, date } = props;

    return (
        <>
            <h1 className="text-xl text-center uppercase">{title}</h1>
            <p className="text-center italic">{date}</p>
        </>
    );
};

const PostBody = (props: { content: string }) => {
    const { content } = props;

    return (
        <div className="my-12 mx-auto w-3/4">
            <div
                className="markdown col-span-2"
                dangerouslySetInnerHTML={{ __html: content }}
            />
        </div>
    );
};

const Post = (props: { data: any }) => {
    const { data } = props;
    const { frontmatter, html } = data.markdownRemark;

    return (
        <Layout title="Post">
            <PageWrapper>
                <Navigation />
                <PostContainer>
                    <PostHeader
                        title={frontmatter.title}
                        date={frontmatter.date}
                    />
                    <PostBody content={html} />
                </PostContainer>
            </PageWrapper>
        </Layout>
    );
};

export default Post;
